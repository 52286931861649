import { cx } from 'emotion';
import * as React from 'react';
import './MetricPickerContainer.less';

interface Props {
	title: JSX.Element | string;
	content: JSX.Element | string;
	isScrollable?: boolean;
}

export const MetricPickerContainer = ({ title, content, isScrollable = false }: Props) => {
	const classes = cx('metric-picker-container', { scrollable: isScrollable });

	return (
		<div className={classes}>
			<div className="title">{title}</div>
			<div className="content">{content}</div>
		</div>
	);
};
