export { default as Assign } from 'crocks/Assign';
export { default as Async } from 'crocks/Async';
export { default as maybeToAsync } from 'crocks/Async/maybeToAsync';

export { default as converge } from 'crocks/combinators/converge';
export { default as flip } from 'crocks/combinators/flip';
export { default as identity } from 'crocks/combinators/identity';

export { default as assign } from 'crocks/helpers/assign';
export { default as assoc } from 'crocks/helpers/assoc';

export { default as compose } from 'crocks/helpers/compose';
export { default as fromPairs } from 'crocks/helpers/fromPairs';
export { default as omit } from 'crocks/helpers/omit';
export { default as pick } from 'crocks/helpers/pick';
export { default as pipe } from 'crocks/helpers/pipe';
export { default as getPropOr } from 'crocks/helpers/getPropOr';
export { default as getPathOr } from 'crocks/helpers/getPathOr';
export { default as unary } from 'crocks/helpers/unary';
export { default as unit } from 'crocks/helpers/unit';

export { default as IO } from 'crocks/IO';

export { default as and } from 'crocks/logic/and';
export { default as ifElse } from 'crocks/logic/ifElse';
export { default as not } from 'crocks/logic/not';
export { default as or } from 'crocks/logic/or';
export { default as when } from 'crocks/logic/when';

export { default as Maybe } from 'crocks/Maybe';
export { default as find } from 'crocks/Maybe/find';
export { default as getProp } from 'crocks/Maybe/getProp';
export { default as getPath } from 'crocks/Maybe/getPath';
export { default as safe } from 'crocks/Maybe/safe';

export { default as Pair } from 'crocks/Pair';
export { default as toPairs } from 'crocks/Pair/toPairs';

export { default as concat } from 'crocks/pointfree/concat';
export { default as equals } from 'crocks/pointfree/equals';
export { default as filter } from 'crocks/pointfree/filter';
export { default as map } from 'crocks/pointfree/map';
export { default as reduce } from 'crocks/pointfree/reduce';
export { default as traverse } from 'crocks/pointfree/traverse';

export { default as isArray } from 'crocks/predicates/isArray';
// export { default as isDate } from 'crocks/predicates/isDate';
export const isDate = x => Object.prototype.toString.apply(x) === '[object Date]' && !isNaN(x.valueOf());
export { default as isDefined } from 'crocks/predicates/isDefined';
export { default as isNumber } from 'crocks/predicates/isNumber';
export { default as isTrue } from 'crocks/predicates/isTrue';
export { default as pathSatisfies } from 'crocks/predicates/pathSatisfies';
export { default as propEq } from 'crocks/predicates/propEq';
export { default as propSatisfies } from 'crocks/predicates/propSatisfies';
