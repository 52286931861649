import * as React from 'react';
import './MetricAddButtonsContainer.less';

interface Props {
	select;
	numbers;
}

export const MetricAddButtonsContainer = ({ select, numbers }: Props) => {
	return (
		<div className="metric-add-buttons">
			<div className="select">{select}</div>
			<div className="numbers">{numbers}</div>
		</div>
	);
};
