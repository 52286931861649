import { model } from '..';

export type TableAction =
	| 'SET_LAST_ROW_OF_HOVERED_CONTAINER'
	| 'SET_ACTIVE_LEVEL'
	| 'SET_TABLE_WIDTH'
	| 'SET_SCROLLED'
	| 'UPDATE_PINNED_ROWS';

type Reducer = { [key in TableAction]: any };

export const tableReducer: Reducer = {
	SET_LAST_ROW_OF_HOVERED_CONTAINER,
	SET_ACTIVE_LEVEL,
	SET_TABLE_WIDTH,
	SET_SCROLLED,
	UPDATE_PINNED_ROWS
};

function SET_LAST_ROW_OF_HOVERED_CONTAINER(containerId: string) {
	model.flattenedData.forEach((row, index) => {
		const pathEquals = containerId !== 'outOfTable' && row.path.slice(0, -1).join('-') === containerId;
		if (row.hovered && row.last && !pathEquals) model.flattenedData[index] = { ...row, hovered: false };
		if (!row.hovered && row.last && pathEquals) model.flattenedData[index] = { ...row, hovered: true };
	});
}
function SET_ACTIVE_LEVEL(level: number) {
	model.activeLevel = level;
}
function SET_TABLE_WIDTH(width: number) {
	model.tableWidth = width;
}
function SET_SCROLLED(scrolled: boolean) {
	model.scrolled = scrolled;
}

function UPDATE_PINNED_ROWS(startIndex: number) {
	updatePinnedRows(startIndex);
}

const updatePinnedRows = (startIndex: number) => {
	const maxNumberOfPinnedRows = model.breakdowns.allIds.length - 1;
	if (startIndex === 0) {
		for (const [index, row] of model.rowsToShow.entries()) {
			if (row.path.length > maxNumberOfPinnedRows) break;
			if (index === 0) {
				model.pinnedRows[index] = row;
				continue;
			}
			if (row.path.length === model.pinnedRows[index - 1].path.length) break;
			model.pinnedRows[index] = row;
		}
	} else {
		_updatePinnedRows(startIndex, maxNumberOfPinnedRows, model.rowsToShow);
	}
};

const _updatePinnedRows = (startIndex: number, maxNumberOfPinnedRows: number, rowsToShow: Row[]) => {
	for (const i in rowsToShow.slice(0, startIndex + maxNumberOfPinnedRows)) {
		const iAsInteger = parseInt(i, 10);
		if (iAsInteger === 0) {
			const zeroRow = rowsToShow[iAsInteger];
			if (zeroRow.path.length > maxNumberOfPinnedRows) {
				continue;
			}
			model.pinnedRows[zeroRow.path.length - 1] = zeroRow;
		}
		const index = iAsInteger + 1;
		const row = rowsToShow[index];
		if (row && row.path.length > maxNumberOfPinnedRows) {
			continue;
		}
		if (row && index < startIndex + 1 + row.path.length) {
			if (
				!model.pinnedRows[row.path.length - 1] ||
				row.path.length === model.pinnedRows[row.path.length - 1].path.length
			) {
				model.pinnedRows[row.path.length - 1] = row;
			}
		}
	}
};
