import { createIntents } from '@/model';

export const sidebarIntents: SidebarIntents = createIntents(
	(present): SidebarIntents => ({
		TOGGLE_SIDEBAR: () => present({ type: 'TOGGLE_SIDEBAR' }),
		TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR: () => present({ type: 'TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR' }),
		TOGGLE_DIMENSIONS_OF_SIDEBAR: () => present({ type: 'TOGGLE_DIMENSIONS_OF_SIDEBAR' })
	})
);

export type SidebarIntents = {
	TOGGLE_SIDEBAR();
	TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR();
	TOGGLE_DIMENSIONS_OF_SIDEBAR();
};
