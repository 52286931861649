export const flattenTableData = (entries, index = 0, path = [], acc = []): DiminishedRow[] => {
	if (entries.length === 0) return acc;
	return flattenTableData(
		entries.slice(1),
		index + 1,
		path,
		acc
			.concat({ path: path.concat(index), first: index === 0, last: entries.length === 1, fields: entries[0].fields })
			.concat(flattenTableData(entries[0].entries, 0, path.concat(index)))
	);
};
