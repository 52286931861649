import { compose } from '@functions/crocks';

type Endomorphism<T> = (a: T) => T;
type Just<T> = {
	map<U>(fn: (a: T) => U): U;
};
type Nothing<T> = {
	map<U>(fn: (a: T) => U): Nothing<T>;
};

type Maybe<T> = Just<T> | Nothing<T>;

type Getter<O, P> = (obj: O) => P;
type Setter<O, P> = (val: P) => (obj: O) => O;

type Lens<O, P> = {
	get: Getter<O, P>;
	set: Setter<O, P>;
};

// export const lens = <O, P>(getter: Getter<O, P>, setter: Setter<O, P>): Lens<O, P> => ({
// 	get: getter,
// 	set: (val: P) => (obj: O) => setter(val)(obj)
// });

// export const view = <O, P>(lens: Lens<O, P>) => (obj: O) => lens.get(obj);
// export const set = <O, P>(lens: Lens<O, P>) => (val: P) => (obj: O) => lens.set(val)(obj);
// export const over = <O, P, U>(lens: Lens<O, P>) => (fn: (a: P) => U) => (obj: O) => lens.set(lens.get(obj).map(fn));

// const composeLense = <A, B, C>(ab: Lens<A, B>, bc: Lens<B, C>) =>
// 	lens((a: A) => bc.get(ab.get(a)), (c: C) => (a: A) => ab.set(bc.set(c)(ab.get(a)))(a));

// export const composeL = <O, P>(...args: Lens<O, P>[]) => {
// 	const lenses = args.reduce((acc, lens) => [lens, ...acc], []);
// 	return lenses.reduce(composeLense);
// };

export const split = (splitStr: string) => (str: string) => str.split(splitStr);
const first = <T>(x: [T]) => x[0];
export const getHeadOfSplit = (splitStr: string) =>
	compose(
		first,
		split(splitStr)
	);
