import { RawFilter } from '@/dsl';
import { model, Model } from '@/model';
import { setModelToFetchTableData } from '@/model/reducer';
import { getMaybeOperatorSchemaByTypeAndOperator, getOperatorTypeOfRawField } from '@dsl/operators';
import { Assign, compose, find, getPath, getProp, propEq } from '@functions/crocks';

export type RawFiltersAction = 'CREATE_RAW_FILTER' | 'REMOVE_RAW_FILTER' | 'UPDATE_RAW_FILTER';

export const rawFiltersReducer = {
	CREATE_RAW_FILTER: createRawFilter,
	REMOVE_RAW_FILTER: removeRawFilter,
	UPDATE_RAW_FILTER: updateRawFilter
};

function createRawFilter({ dimension }) {
	_createRawFilter(dimension)(model);
	model.isRawFiltersSectionOpened = true;
}

function removeRawFilter(rawFilter: RawFilter) {
	compose(
		setModelToFetchTableData,
		_removeRawFilter(rawFilter)
	)(model);
}

function updateRawFilter(payload: { oldRawFilter: RawFilter; update: any }) {
	compose(
		setModelToFetchTableData,
		_updateRawFilter(payload)
	)(model);
}

const _createRawFilter = dimension => (model: Model) => {
	const rawFieldsSchemaofDimensionOption = find(propEq('value')(dimension))(model.rawFieldsSchema);

	model.rawFilters = [
		...model.rawFilters,
		{
			dimension,
			value: '',
			type: rawFieldsSchemaofDimensionOption.chain(getProp('type')).option(''),
			...rawFieldsSchemaofDimensionOption.chain(getPath(['predicates', 0])).option({})
		}
	];

	return model;
};

const _removeRawFilter = (rawFilter: RawFilter) => (model: Model) => {
	const rawFilterAssign = Assign(rawFilter);
	model.rawFilters = model.rawFilters.filter(item => !rawFilterAssign.equals(Assign(item)));

	return model;
};

const _updateRawFilter = (payload: { oldRawFilter: RawFilter; update: any }) => (model: Model) => {
	const { oldRawFilter, update } = payload;
	const oldRawFilterAssign = Assign(oldRawFilter);
	const rawFilterToUpdate = model.rawFilters.findIndex(item => oldRawFilterAssign.equals(Assign(item)));
	const type = getOperatorTypeOfRawField(update.dimension);
	const { operand, label } = getMaybeOperatorSchemaByTypeAndOperator(type)(update.operator).option({});

	model.rawFilters[rawFilterToUpdate] = { ...oldRawFilter, ...update, label, operand };
	return model;
};
