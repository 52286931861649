import { isDebug } from '@/imports';
import { getCompositeStringValueOfMetric, getMetricValueOfCompositeString } from '@/Metrics';
import { Action, reducer } from '@/model/reducer';
import { equals, getProp, map } from '@functions/crocks';
import { raw } from '@nx-js/observer-util';
import { createStore, parseQuery } from 'funwork-js';
import { metricsModel, MetricsModel } from './metrics';
import { rawFiltersModel, RawFiltersModel } from './rawFilters';
import { sessionModel } from './session';
import { sidebarModel, SidebarModel } from './sidebar';
import { tableModel } from './table';

export const devTools = isDebug && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__.connect();

export interface Model extends MetricsModel, SidebarModel, RawFiltersModel, TableModel, SessionModel {
	lastDataFetchId: number;
	isAppReady: boolean;
	breakdownsSchema: BreakdownSchema[];
	columns: [];
	rawFieldsSchema: RawFieldSchema[];
	filtersSchema;
	_filtersSchema;
	tableData;
	flattenedData: Row[];
	tableState: TableState;
	shouldFetchTableData: boolean;
	breakdowns;
	breakdownsScrollLeft: number;
	filterColumnsScrollTop: number;
	rawFilterColumnsScrollTop: number;
	dragStarts: number[];
	openedRows: OpenedRows;
	path: Page[];
	openedBreakdown: number;
	filterArrayInput;
	isRawFiltersSectionOpened: boolean;
	isMetricPickerOpened: boolean;
	orderings;
	predicates;
	_breakdownsSchema;
	tableWidth: number;
	retryLambdaId: string;
}

const _model: Model = {
	...metricsModel,
	...sidebarModel,
	...rawFiltersModel,
	...tableModel,
	...sessionModel,
	_breakdownsSchema: { allIds: [], byId: {} },
	_filtersSchema: { allIds: [], byId: {} },
	breakdowns: { allIds: [], byIds: {} },
	breakdownsSchema: [],
	breakdownsScrollLeft: 0,
	columns: [],
	dragStarts: [],
	filterArrayInput: undefined,
	filterColumnsScrollTop: 0,
	filtersSchema: [],
	flattenedData: [],
	isAppReady: false,
	isMetricPickerOpened: undefined,
	isRawFiltersSectionOpened: undefined,
	lastDataFetchId: 0,
	openedBreakdown: undefined,
	openedRows: {},
	orderings: {
		allIds: [],
		byIds: {}
	},
	path: ['explore'],
	predicates: { allIds: [], byIds: {} },
	rawFieldsSchema: [],
	rawFilterColumnsScrollTop: 0,
	shouldFetchTableData: false,
	tableData: [],
	tableState: 'notLoading',
	tableWidth: 0,
	retryLambdaId: '',
	...deserializeUrlToModel({ path: location.pathname.slice(1), query: parseQuery(location.search) })
};

const onReduce = (proposal, model) => {
	if (devTools) {
		devTools.send(proposal, raw(model));
	}
};

const onModelInit = model => {
	model.isRawFiltersSectionOpened = model.rawFilters.length !== 0;
	if (devTools) {
		devTools.init(raw(model));
	}
};

export const { createIntents, napSetup, routerSetup, model } = createStore<Model, Action>({
	model: {
		onModelInit,
		model: _model
	},
	reducer: {
		reducer,
		onReduce
	}
});

export function serializeModelToUrl(model: Model) {
	const metrics = model.selectedMetrics.map(getCompositeStringValueOfMetric);
	const breakdowns = model.breakdowns.allIds;

	const predicates = model.predicates.allIds.reduce((acc, predicate) => {
		if (model.predicates.byIds[predicate].value) {
			acc[predicate] = model.predicates.byIds[predicate].value;
		}
		return acc;
	}, {});

	const orderings = model.orderings.allIds.reduce((acc, breakdown) => {
		acc[breakdown] = model.orderings.byIds[breakdown].map(ordering => `${ordering.metric}-${ordering.order}`);
		return acc;
	}, {});

	return {
		path: model.path.join('/'),
		query: {
			metrics,
			breakdowns,
			predicates,
			orderings,
			r: model.openedRows,
			retry: model.retryLambdaId,
			// isRawFiltersSectionOpened: model.isRawFiltersSectionOpened || undefined,
			// isDimensionsSecionOpened: model.isDimensionsSecionOpened || undefined,
			// sidebarOpened: model.isSidebarOpened || undefined,
			rawFilters: model.rawFilters
		}
	};
}

export function deserializeUrlToModel({ path, query }) {
	const breakdowns =
		query.breakdowns &&
		query.breakdowns.reduce(
			(acc, breakdown) => {
				acc.byIds[breakdown] = { id: breakdown, size: 10 };
				acc.allIds.push(breakdown);
				return acc;
			},
			{ byIds: {}, allIds: [] }
		);

	const predicates = { byIds: {}, allIds: [] };
	for (const predicate in query.predicates || {}) {
		const [breakdown, metric, operator] = predicate.split('-');
		const id = predicate;
		predicates.byIds[predicate] = {
			id,
			breakdown,
			metric,
			operator,
			value: query.predicates[predicate]
		};
		predicates.allIds.push(predicate);
		if (!breakdowns.byIds[breakdown].filters) {
			breakdowns.byIds[breakdown].filters = [];
		}
		if (!breakdowns.byIds[breakdown].filters.find(equals(predicate))) {
			breakdowns.byIds[breakdown].filters.push(predicate);
		}
	}

	const orderings = { byIds: {}, allIds: [] };
	for (const breakdown in query.orderings || {}) {
		for (const ordering of query.orderings[breakdown]) {
			if (!orderings.byIds[breakdown]) {
				orderings.byIds[breakdown] = [];
			}
			const [metric, order] = ordering.split('-');
			orderings.byIds[breakdown].push({
				metric,
				order
			});
		}
		orderings.allIds.push(breakdown);
	}

	const queryToMetrics = query =>
		getProp('metrics')(query)
			.map(map(getMetricValueOfCompositeString))
			.map(selectedMetrics => ({ selectedMetrics }))
			.option({});

	const pathSplitted = path.split('/');

	const tableState = breakdowns && breakdowns.allIds.length === 0 ? 'notLoading' : 'loading';

	return {
		...(pathSplitted.length && { path: pathSplitted }),
		...(breakdowns ? { breakdowns } : {}),
		predicates,
		orderings,
		tableState,
		openedRows: query.r || {},
		isMetricPickerOpened: query.isMetricPickerOpened,
		rawFilters: query.rawFilters || [],
		retryLambdaId: query.retry,
		// isRawFiltersSectionOpened: query.isRawFiltersSectionOpened || undefined,
		// isDimensionsSecionOpened: query.isDimensionsSecionOpened || undefined,
		// isSidebarOpened: query.sidebarOpened || undefined,
		...queryToMetrics(query)
	};
}

export interface OrderBy {
	value: string;
	label: string;
	order: 'ASC' | 'DESC';
}

export type Operand = 'number' | 'string' | 'string[]' | 'isoDate';

export interface Predicate {
	operator: string;
	label: string;
	operand: Operand;
	value?: string | string[] | number;
}

export interface Filter {
	[key: string]: Predicate;
}

export interface Filters {
	[key: string]: Filter;
}

export interface FilteredBy {
	column: string;
	predicates: Predicate[];
}

export interface RawFilters {
	[key: string]: Filter;
}

export interface GroupBy {
	value: string;
	label: string;
}

export interface Breakdown {
	orderBy?: OrderBy[];
	groupBy: GroupBy;
	filters?: Filters;
	size: number;
}

export interface BreakdownParam {
	type: string;
	label: string;
}

export interface BreakdownSchema {
	value: string;
	label: string;
	params?: BreakdownParam[];
}

export interface RawFieldSchema {
	value: string;
	label: string;
	predicates: Predicate[];
	type: string;
}

export interface OpenedRows {
	[key: number]: OpenedRows | { '.': '.' };
}

export type TableState = 'notLoading' | 'loading' | 'loaded' | 'error';

export interface _breakdown {
	id: string;
	size: number;
	filters: string[];
	orderBy: string[];
}

export interface _filter {
	id: string; // {breakdownId_metricId}
	metricId: string;
	predicates: string[];
}

export interface _filters {
	byId: { [key: string]: _filter };
	allIds: string[];
}

export interface _predicate {
	id: string; // {breakdownId_metricId_operatorId}
	operatorId: string;
	value: number | string | string[];
}

export interface _predicates {
	byId: { [key: string]: _predicate };
	allIds: string[];
}

export interface _ordering {
	id: string; // {order_metricId}
	order: 'ASC' | 'DESC';
	metricId: string;
}

export interface _orderings {
	byId: { [key: string]: _ordering };
	allIds: string[];
}
