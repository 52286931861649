export interface MetricsModel {
	metricsSchema: MetricsSchema;
	selectedMetrics: SelectedMetric[];
	checkedCumulativeMetrics: MetricValue[];
	selectedAddCumulativeMetricPreset: 'recommended' | 'early' | 'custom';
	addCumulativeMetricsOptions;
	metricFilterInput: string;
}

export const metricsModel: MetricsModel = {
	metricsSchema: {},
	selectedMetrics: [],
	checkedCumulativeMetrics: [],
	selectedAddCumulativeMetricPreset: 'recommended',
	addCumulativeMetricsOptions: getAddCumulativeMetricsOptions(),
	metricFilterInput: ''
};

function getAddCumulativeMetricsOptions() {
	return {
		recommended: [1, 2, 3, 4, 5, 6, 7, 14, 28, 90],
		early: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
		custom: []
	};
}

export type MetricValue = {
	value: string;
	params?: { [key: string]: number | string };
};

export type MetricFormat = {
	round?: number;
	postfix?: string;
	prefix?: string;
};

type MetricParam = {
	type: string;
	required: boolean;
};

type MetricParams = {
	[key: string]: MetricParam;
};

export type MetricSchema = {
	value: string;
	label: string;
	type: string;
	format?: MetricFormat;
	params?: MetricParams;
};

type MetricsSchema = {
	[key: string]: MetricSchema;
};

export type SelectedMetric = MetricValue & {
	label: string;
	type: string;
	format?: MetricFormat;
};
