import * as React from 'react';
import './MetricPickerTitle.less';

interface Props {
	icon: JSX.Element;
	title: string;
	right?: JSX.Element | string;
}

export const MetricPickerTitle = ({ icon, title, right }: Props) => {
	return (
		<div className="metric-picker-title">
			<div className="metric-picker-title-left">
				<span className="icon">{icon}</span>
				<span className="title">{title}</span>
			</div>
			<div className="metric-picker-title-right">{right}</div>
		</div>
	);
};
