import { intents, Intents } from '@/intents';
import { model, Model, napSetup as _napSetup } from '@/model';
import { Maybe } from '@functions/crocks';
import { getPathWithoutStaging } from '@dsl/metrics';

export const napSetup = () =>
	_napSetup<Model, Intents>({ model, intents, naps: [initializeApp, fetchTableData, setHovered] });

const initializeApp = (model: Model, intents: Intents) => {
	if (model.user.equals(Maybe.Nothing())) return;
	if (model.isAppReady) return;
	intents.INITIALIZE_APP();
};

let fetchId = 0;
const fetchTableData = (model: Model, intents: Intents) => {
	if (model.user.equals(Maybe.Nothing())) return;
	if (!model.isAppReady) return;
	if (!model.shouldFetchTableData) return;
	if (model.lastDataFetchId === fetchId) return;
	if (model.breakdowns.allIds.length === 0) return;
	fetchId = model.lastDataFetchId;
	intents.FETCH_TABLE_DATA();
};

const setHovered = (model: Model) => {
	model.rowsToShow = model.flattenedData.filter(item => item.visible);
};
