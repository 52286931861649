import { model } from '@/model';
import { SignOut } from '@/_components/SignOut';
import { Navigation } from '@components/Navigation/Navigation';
import { Logo } from '@components/reusable/Logo/Logo';
import * as User from '@/User';
import { useOnClickOutside } from '@hooks/';
import { Popup } from '@reusable/Popup/Popup';
import { view } from 'funwork-js';
import * as React from 'react';
import './Header.less';

export const Header = view(() => {
	const [userSettingsOpened, setUserSettingsOpened] = React.useState(false);

	const popupRef = React.useRef(null);

	useOnClickOutside(popupRef, () => {
		setTimeout(() => {
			setUserSettingsOpened(false);
		}, 0);
	});

	return (
		<div component="header">
			<div className="header">
				<div className="left">
					<Logo />
					<Navigation />
				</div>
				{model.user
					.map(user => (
						<div className="relative">
							<User.View user={user} onAvatarClick={() => setUserSettingsOpened(true)}></User.View>
							<Popup
								ref={popupRef}
								opened={userSettingsOpened}
								className="w-full border shadow-lg py-2 bg-white right-0 absolute rounded"
								arrowPosition="none"
							>
								<div>
									<SignOut className="hover:bg-primary p-2-4" />
								</div>
							</Popup>
						</div>
					))
					.option(null)}
			</div>
		</div>
	);
});
