import { intents } from '@/intents';
import { model } from '@/model';
import { debounce } from '@functions/debounce';
import * as Reusable from '@/Reusable';
import { css, cx } from 'emotion';
import { view } from 'funwork-js';
import * as React from 'react';
import './TableRow.less';
import { CellList } from '@/_components/_Table/Table';

interface Props {
	onClick?(): void;
	className?: string;
	fields;
	numberOfCells: number;
	level: number;
	path: number[];
	opened: boolean;
	last?: boolean;
	header?: boolean;
	active?: boolean;
	hovered?: boolean;
	visible?: boolean;
	style;
}

type loadNext = (level: number) => void;
const loadNext: loadNext = level => intents.FETCH_MORE_TABLE_DATA(level)(0)(10);

type onMouseEnter = (level: number, joinedPath: string) => void;
const onMouseEnter: onMouseEnter = debounce(300, (level: number, joinedPath: string = 'outOfTable') => {
	intents.SET_LAST_ROW_OF_HOVERED_CONTAINER(joinedPath);
	intents.SET_ACTIVE_LEVEL(level);
});

type toggleRow = (a?: () => void) => (b: number[]) => (c: boolean) => (d: React.FormEvent<HTMLInputElement>) => void
const toggleRow: toggleRow = callback => path => open => e => {
	e.stopPropagation();
	// intents.TOGGLE_ROW({ path: path.slice(0, -1), index: path.slice(-1) });
	if (callback) {
		callback();
	}
	intents.TOGGLE_ROW({ path, open });
};

export const TableRow = view(
	({
		onClick,
		className,
		fields,
		numberOfCells,
		level,
		path,
		style,
		opened = false,
		last = false,
		header = false,
		active = false,
		hovered = false,
		visible = false
	}: Props) => {
		const { tableWidth } = model;

		const gridTemplateColumns = header
			? css`
					grid-template-columns:
						repeat(2, minmax(calc(var(--grid2) * 18), auto)) repeat(${numberOfCells - 2}, calc(var(--grid2) * 18))
						calc(var(--grid2) * 19);
			  `
			: css`
					grid-template-columns:
						repeat(2, minmax(calc(var(--grid2) * 18), auto)) repeat(${numberOfCells - 1}, calc(var(--grid2) * 18))
						var(--grid2);
			  `;

		const classes = cx(
			'table-row',
			className,
			{
				opened,
				header,
				[`level-${level}`]: !header,
				hidden: !header && !visible
			},
			gridTemplateColumns
		);
		const colorClass = cx('color', `bg-level${level + 1}`);

		const joinedPath = path.slice(0, -1).join('-');

		return (
			<div
				className={classes}
				onMouseEnter={() => onMouseEnter(level, joinedPath)}
				style={{
					...style,
					...(last && hovered && { zIndex: 70 })
				}}
			>
				<CellList
					onClick={toggleRow(onClick)(path)(!opened)}
					fields={fields}
					path={path}
					header={header}
					active={active}
					last={last}
				/>

				{!header && <div className={colorClass} />}
				{last && hovered && <LoadMoreBar onClick={() => loadNext(level)} level={level} tableWidth={tableWidth} />}
			</div>
		);
	}
);
const LoadMoreBar = view(({ onClick, level, tableWidth }) => {
	return (
		<div className="load-more-bar absolute center-v h-3 top-row-height w-full bg-row-loadmore" onClick={onClick}>
			<div className="center-v h-full bg-primary" style={{ left: 0, position: 'sticky', flexBasis: `${level * 16}px` }}>
				<div className="center-v absolute" style={{ left: `${tableWidth / 2}px` }}>
					<Reusable.ViewIcon name="ellipsis-h" />
				</div>
			</div>
			<div className="h-full" />
		</div>
	);
});
