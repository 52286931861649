import { useInput } from '@hooks/';
import * as Reusable from '@/Reusable';
import * as React from 'react';
import { InputPresentational } from '@reusable/InputPresentational/InputPresentational';

interface Props {
	onAdd;
}

export const MetricAddCustomNumberButtonPresentational = ({ onAdd }: Props) => {
	const { value, setValue } = useInput('');

	const onChange = e => {
		if (e && e.target.value) {
			setValue(parseInt(e.target.value, 10));
		}
	};

	const onKeyPress = e => {
		if (e.key === 'Enter') {
			if (!Number.isInteger(value as number) && value === 0) return;
			onAdd(value);
			setValue('');
		}
	};

	return (
		<div className="center-v ml-2">
			<div className="mr-4">
				<InputPresentational
					value={value}
					onChange={onChange}
					onKeyPress={onKeyPress}
					type="number"
					placeholder="Add day..."
				/>
			</div>
			<button
				className="center rounded p-2-4 bg-c-accent2-pale text-white h-8 hover:bg-c-accent2-pale-darkened"
				onClick={() => onAdd(value)}
			>
				<div className="mr-2">
					<Reusable.ViewIcon name="plus" />
				</div>
				<div>Add day</div>
			</button>
		</div>
	);
};
