import { routerSetup } from '@router';
// import { initAuth } from '@services/cognito';
import '@styles/';
import React from 'react';
import { render } from 'react-dom';
import { initSession } from './Api';
import { napSetup } from './nap';
import { Wrapper } from './Wrapper';

// export const session = initAuth();
export const session = initSession();
window.session = session;
napSetup();
routerSetup();

render(<Wrapper />, document.querySelector('#app'));

if (module.hot) {
	module.hot.accept();
}
