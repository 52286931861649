import { Screen } from '@components/Screen';
import { Maybe } from '@functions/crocks';
import { Login } from '@screens/Login/Login';
import { view } from 'funwork-js';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { getPathWithoutStaging } from './Metrics';
import { model } from './model';

export const App = hot(module)(
	view(() => {
		const { user } = model;
		const path = getPathWithoutStaging(model);

		return (
			<div className="default">{user.equals(Maybe.Nothing()) || path[0] === 'login' ? <Login /> : <Screen />}</div>
		);
	})
);
