import { classNames } from '@/imports';
import * as Reusable from '@/Reusable';
import * as React from 'react';
import { SidebarItemPresentational } from '@components/Sidebar/SidebarItemPresentational';
import { SidebarDropdownPresentational } from '@components/Sidebar/SidebarDropdownPresentational';
import { view } from 'funwork-js';

interface Props {
	icon: JSX.Element;
	text: string;
	opened: boolean;
	onAdd;
	onToggle;
	options;
}

export const DimensionCategoryLabelPresentational = view(({ icon, text, opened, onAdd, onToggle, options }: Props) => {
	const iconClass = classNames('transition-03', { '-rotateZ-180': opened });

	return (
		<SidebarItemPresentational
			onClick={onToggle}
			onIconClick={onToggle}
			icon={<Reusable.ViewIcon className={iconClass} size="nano" name="chevron-down" />}
			className="dimension-category-label-presentational cursor-auto"
		>
			<div className="center-v px-6 pl-10 w-full">
				<div className="center-v mr-2">{icon}</div>
				<SidebarDropdownPresentational
					options={options}
					onChange={onAdd}
					trigger={
						<div className="center-v">
							<div className="mr-2 uppercase text-sm">{text}</div>
							<div className="center-v text-sidebar-font-dark">
								<Reusable.ViewIcon name="plus" size="nano" />
							</div>
						</div>
					}
					icon={null}
				/>
			</div>
		</SidebarItemPresentational>
	);
});
