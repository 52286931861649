import { intents } from '@/intents';
import { getLabelOfMetric } from '@/Metrics';
import { model } from '@/model';
import { TableHeader } from '@reusable/Table/TableHeader/TableHeader';
import { TableRow } from '@reusable/Table/TableRow/TableRow';
import { view } from 'funwork-js';
import React, { forwardRef } from 'react';
import { VariableSizeList as List } from 'react-window';

const outerElementType = view(
	forwardRef<HTMLDivElement>(({ children, ...props }, ref) => {
		const headerRow = {
			fields: [{ value: '_key', label: 'Name' }]
				.map(field => ({ key: { value: field.value }, value: field.label }))
				.concat(
					model.selectedMetrics.map(field => {
						const label = getLabelOfMetric(field);
						return { key: { value: field.value }, value: label };
					})
				),
			path: [],
			header: true,
			active: false,
			hovered: false,
			level: -1
		};

		return (
			<div ref={ref} {...props}>
				<TableHeader style={{ height: 40, top: 0, position: 'sticky', zIndex: 100 }} row={headerRow} />
				{model.pinnedRows.map((pinnedRow, i) => (
					<TableRow
						style={{
							position: 'sticky',
							top: i * 32 + 40,
							width: '100%',
							height: 32,
							zIndex: 35 + (14 - pinnedRow.path.length) * 2 - 1
						}}
						key={`${pinnedRow.path.join('-')}`}
						{...pinnedRow}
						level={pinnedRow.path.length - 1}
						active={pinnedRow.active}
						hovered={false}
						numberOfCells={pinnedRow.fields.length}
					/>
				))}
				{children}
			</div>
		);
	})
);

export const RowList = view(() => {
	const [scrolled, setScrolled] = React.useState();

	const outerRef = React.useRef();
	const listRef = React.createRef<List>();

	const setNameColumnShadow = () => {
		const { scrollLeft } = outerRef.current;
		if (scrollLeft === 0 && scrolled) {
			setScrolled(false);
			return;
		}
		if (scrollLeft !== 0 && !scrolled) {
			setScrolled(true);
		}
	};

	const onItemsRendered = ({ overscanStartIndex }: { overscanStartIndex: number }) => {
		intents.UPDATE_PINNED_ROWS(overscanStartIndex);
	};

	const onClick = (index: number) => {
		if (listRef.current) {
			listRef.current.resetAfterIndex(index, true);
		}
	};

	React.useEffect(() => {
		onClick(0);
	}, [model.openedRows]);

	return (
		<List
			ref={listRef}
			outerRef={outerRef}
			height={996}
			itemCount={model.rowsToShow.length}
			overscanCount={1}
			itemSize={index => {
				if (index <= model.pinnedRows.length) {
					return 32;
				}
				return model.rowsToShow[index].opened || model.rowsToShow[index].last ? 40 : 32;
			}}
			onScroll={() => {
				setNameColumnShadow();
				intents.SET_LAST_ROW_OF_HOVERED_CONTAINER('');
			}}
			outerElementType={outerElementType}
			width={model.tableWidth}
			onItemsRendered={onItemsRendered}
		>
			{({ index, style }) => {
				const row = model.rowsToShow[index];

				return (
					<TableRow
						onClick={() => onClick(index)}
						style={{
							...style,
							// @ts-ignore
							top: style.top + 40,
							height: style.height,
							zIndex: 35 + (14 - row.path.length) * 2
						}}
						// key={`${row.path.join('-')}`}
						key={`${row.path.join('-')}-${index}-${style.height}`}
						{...row}
						level={row.path.length - 1}
						active={row.active}
						hovered={row.hovered}
						numberOfCells={row.fields.length}
					/>
				);
			}}
		</List>
	);
});

// @ts-ignore
RowList.displayName = 'RowList';
