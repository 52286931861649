import React from 'react';

interface Props {
	user: User;
	onAvatarClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const View = ({ user, onAvatarClick }: Props) => {
	return (
		<div className="user">
			<div className="center-v">
				<div className="mr-4">
					<div>
						{user.name} {user.surname}
					</div>
					<div className="text-xxs opacity-half">{user.email}</div>
				</div>
				<div className="center">
					<ViewAvatar onClick={onAvatarClick} imgSrc={user.profilePictureURL} />
				</div>
			</div>
		</div>
	);
};

const ViewAvatar = ({ onClick, imgSrc }) => {
	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<img
			width="48px"
			height="48px"
			onClick={onClick}
			className="cursor-pointer rounded-full"
			src={imgSrc}
			alt="avatar"
		></img>
	);
};
