import { classNames } from '@/imports';
import * as Reusable from '@/Reusable';
import { view } from 'funwork-js';
import * as React from 'react';
import './TableCell.less';
import { unit } from '@functions/crocks';

interface Props {
	className: string;
	order?: number;
	metric: string;
	padding: number;
	bg: string;
	value: string;
	header?: boolean;
	first?: boolean;
	onClick?;
	icon;
	opened?: boolean;
	last?: boolean;
	lastRow?: boolean;
	level: number;
	lastLevel?: boolean;
}

export const TableCell = view(
	({
		className = '',
		order,
		metric,
		padding,
		bg = '',
		value,
		header = false,
		first = false,
		onClick,
		icon,
		opened = false,
		last = false,
		lastRow = false,
		level,
		lastLevel = false
	}: Props) => {
		const cellClass = classNames(metric, `level-${level}`, {
			className,
			opened,
			header,
			last,
			'font-courier-new': !header,
			'font-bold': header,
			'last-row': lastRow,
			'left-0': first
		});
		const colorClass = classNames('color', `bg-level${level + 1}`);
		const paddingStyle = padding ? { paddingLeft: `${padding}px` } : {};
		const style = { ...paddingStyle, backgroundColor: bg };

		return (
			<div component="table-cell" style={style} className={cellClass}>
				<div className="cell-content" onClick={lastLevel ? unit : onClick}>
					{order && <div className={colorClass} />}
					{order && <span className="order">{`${order}. `}</span>}
					<span className="cell-content-value" title={value}>
						{icon && <div className="mr-2">{icon}</div>}
						{value}
					</span>
					{order && !lastLevel && (
						<span className="expand">
							<Reusable.ViewIcon name="chevron-down" size="nano" weight="solid" />
						</span>
					)}
				</div>
			</div>
		);
	}
);
