import * as React from 'react';
import './MetricPickerLeftLayout.less';

interface Props {
	top: JSX.Element;
	bottom: JSX.Element;
}

export const MetricPickerLeftLayout = ({ top, bottom }: Props) => {
	return (
		<div className="metric-picker-left-layout">
			<div className="top">{top}</div>
			<div className="bottom">{bottom}</div>
		</div>
	);
};
