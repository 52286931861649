export function debounce(delay, func, immediate = false) {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(this, args);
		};

		const callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, delay);

		if (callNow) func.apply(this, args);
	};
}

// export function debounce(delay, fn) {
// 	let timerId;
// 	return (...args) => {
// 		if (timerId) {
// 			clearTimeout(timerId);
// 		}
// 		timerId = setTimeout(() => {
// 			fn(...args);
// 			timerId = null;
// 		}, delay);
// 	};
// }
