import { cx } from 'emotion';
import * as React from 'react';
import { createPortal } from 'react-dom';
import './Modal.less';

interface Props {
	isOpened: boolean;
	className?: string;
	onOpen?;
	onBeforeOpen?;
	onClose?;
	children: string | JSX.Element;
}

export const Modal = ({ isOpened, className, onOpen, onBeforeOpen, onClose, children }: Props) => {
	if (!isOpened) return null;
	if (onBeforeOpen) onBeforeOpen();

	const classes = cx('modal', className);

	const onClick = () => {
		if (onClose) {
			onClose();
		}
	};

	const stopPropagationClick = e => {
		e.stopPropagation();
	};

	const portal = (
		<div className="overlay" onClick={onClick}>
			<div className={classes} onClick={stopPropagationClick}>
				{children}
			</div>
		</div>
	);

	React.useEffect(() => {
		if (onOpen) onOpen();
	}, []);

	return createPortal(portal, document.body);
};
