import * as React from 'react';

interface BindToInput {
	value: string | number;
	onChange(): void;
}

interface UseInputReturn {
	value: string | number;
	hasValue: boolean;
	bindToInput: BindToInput;
	bind: BindToInput;
	setValue(val: string | number): void;
	onChange(): void;
	clear(): void;
}

export const useInput = (initial: string | number): UseInputReturn => {
	const isNumber = typeof initial === 'number';
	const [value, setValue] = React.useState(initial);
	const onChange = React.useCallback(e => setValue(e.target.value), []);

	return {
		value,
		setValue,
		onChange,
		hasValue: value !== undefined && value !== null && (!isNumber ? value.trim && value.trim() !== '' : true),
		clear: React.useCallback(() => setValue(''), []),
		bindToInput: {
			value,
			onChange
		},
		bind: {
			value,
			onChange: setValue
		}
	};
};

export const useOnClickOutside = (ref, handler) => {
	React.useEffect(() => {
		const listener = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}

			handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
};
