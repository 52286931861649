import * as Reusable from '@/Reusable';
import * as React from 'react';
import './MetricPickerTopPresentational.less';
import { InputPresentational } from '@reusable/InputPresentational/InputPresentational';
import { view } from 'funwork-js';

interface Props {
	onClose;
	onInput;
	inputValue: string;
}

export const MetricPickerTopPresentational = view(({ onClose, onInput, inputValue }: Props) => {
	const searchbarRef = React.useRef();

	React.useEffect(() => {
		if (searchbarRef.current) searchbarRef.current.focus();
	});

	return (
		<div className="metric-picker-top">
			<div className="metric">
				<Reusable.ViewIcon name="plus-square" size="large" />
				<span className="text">Metrics</span>
			</div>
			<div className="relative center-v">
				<InputPresentational
					placeholder="filter metrics"
					value={inputValue}
					ref={searchbarRef}
					onChange={e => {
						onInput(e.target.value);
					}}
				/>
				<Reusable.ViewIcon className="absolute right-0 -translateX-2" name="search" />
			</div>
			<Reusable.ViewIcon name="times" weight="solid" size="large" onClick={onClose} />
		</div>
	);
});
