import * as React from 'react';
import { SidebarDropdownPresentational } from '@components/Sidebar/SidebarDropdownPresentational';

interface Props {
	options;
	value;
	onChange;
	index: number;
}

export const DimensionSortingsDropdownPresentational = ({ options, value, onChange, index }: Props) => {
	return (
		<div className="dimension-sortings-dropdown-presentational center-v">
			<span className="text-sidebar-font-light underline">{index + 1}.&nbsp;</span>
			<SidebarDropdownPresentational
				className="text-sidebar-font-light underline"
				options={options.map(item => ({ ...item, label: <div className="ellipsis max-w-20">{item.text}</div> }))}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};
