import { model } from '@/model';

export type SidebarAction = 'TOGGLE_SIDEBAR' | 'TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR' | 'TOGGLE_DIMENSIONS_OF_SIDEBAR';

export const sidebarReducer = {
	TOGGLE_SIDEBAR: toggleSidebar,
	TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR: toggleSidebarRawFilters,
	TOGGLE_DIMENSIONS_OF_SIDEBAR: toggleSidebarDimensions
};

function toggleSidebar() {
	model.isSidebarOpened = !model.isSidebarOpened;
}

function toggleSidebarDimensions() {
	model.isDimensionsSecionOpened = !model.isDimensionsSecionOpened;
}

function toggleSidebarRawFilters() {
	model.isRawFiltersSectionOpened = !model.isRawFiltersSectionOpened;
}
