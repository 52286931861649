import { model, Model } from '@/model';
import { getPath, equals } from '@functions/crocks';

// --------------------------------------------------------- MODEL ---------------------------------------------------

export type Metric =
	| 'spend'
	| 'clicks'
	| 'impressions'
	| 'installs'
	| 'purchases'
	| 'payers'
	| 'conversion'
	| 'eligible_players'
	| 'start_day'
	| 'last_active_day'
	| 'eligible_days'
	| 'daily_users'
	| 'gross_iap_revenue'
	| '_gross_ad_revenue'
	| 'gross_total_revenue'
	| 'net_iap_revenue'
	| 'net_ad_revenue'
	| 'net_total_revenue'
	| 'cost_per_install'
	| 'click_to_install_rate'
	| 'click_through_rate'
	| '_average_cohort_lifetime_items_numerator'
	| '_average_cohort_lifetime_items_denominator'
	| 'average_cohort_lifetime'
	| 'gross_average_revenue_per_user'
	| 'net_average_revenue_per_user'
	| 'return_on_investment'
	| 'gross_return_on_active_spend'
	| 'net_return_on_active_spend'
	| '_exact_retention_value'
	| 'exact_retention';

type MetricValue = {
	value: string;
	params?: { [key: string]: number | string };
};

type MetricFormat = {
	round?: number;
	postfix?: string;
	prefix?: string;
};

type MetricParam = {
	type: string;
	required: boolean;
};

type MetricParams = {
	[key: string]: MetricParam;
};

type MetricSchema = {
	value: string;
	label: string;
	type: string;
	format?: MetricFormat;
	params?: MetricParams;
};

type MetricsSchema = {
	[key: string]: MetricSchema;
};

type SelectedMetric = MetricValue & {
	label: string;
	type: string;
	format?: MetricFormat;
};

// ------------------------------------------------------ FUNCTIONS --------------------------------------------------

export const metricToDropdown = metric => ({
	value: getCompositeStringValueOfMetric(metric),
	text: getLabelOfMetric(metric)
});

export const getLabelOfMetric = (metric: MetricSchema | SelectedMetric): string =>
	getPath(['params', 'day_of_activity'])(metric)
		.map(item => `D${item} ${metric.label}`)
		.option(metric.label);

export const getCompositeStringValueOfMetric = (metric: MetricValue): string =>
	getPath(['params', 'day_of_activity'])(metric)
		.map(item => `d${item}_${metric.value}`)
		.option(metric.value);

export function getCumulativeMetricMatch(metric) {
	return metric.match(/^d(\d+)_(.*)$/);
}

export const getMetricValueOfCompositeString = (compositeString: string): MetricValue => {
	const checked = getCumulativeMetricMatch(compositeString);
	return checked
		? { value: checked[2], params: { day_of_activity: parseInt(checked[1], 10) } }
		: { value: compositeString };
};

export const getPathWithoutStaging = (model: Model) => {
	const { path } = model;
	return path[0] === 'staging' ? path.slice(1) : path;
};

export const getRowsToShow = () => model.flattenedData.filter(row => row.visible);

export const isLastActiveDay = equals('last_active_day');
export const isRoi = equals('return_on_investment');
export const isGrossRoas = equals('gross_return_on_active_spend');
export const isNetRoas = equals('net_return_on_active_spend');
