import { getPathWithoutStaging } from '@/Metrics';
import { model } from '@/model';
import * as Home from '@/Page/Home';
import { Sidebar } from '@/Page/Home/sidebar';
import { TableControls } from '@/Page/Home/table';
import * as Retry from '@/Page/Retry';
import { view } from 'funwork-js';
import * as React from 'react';
import { Header } from './Header/Header';
import './Screen.less';

export const Screen = view(() => {
	const path = getPathWithoutStaging(model);

	return (
		<div className="screen">
			<div className="sidebar">
				<Sidebar />
			</div>
			<div className="content">
				<Header />
				{(path[0] === 'explore' || path[0] === '') && (
					<>
						<TableControls />
						<Home.View />
					</>
				)}
				{path[0] === 'lambda-retry' && <Retry.View />}
			</div>
		</div>
	);
});
