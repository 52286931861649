import { useInput } from '@hooks/';
import * as React from 'react';
import './Login.less';
import { intents } from '@/intents';
import { view } from 'funwork-js';
import { Auth } from 'aws-amplify';

export const Login = view(() => {
	const login = useInput('');
	const password = useInput('');

	return (
		<div component="login">
			<div className="form">
				<div className="text">Welcome</div>
				<div className="inputs">
					<input className="input" type="text" {...login.bindToInput} placeholder="login" />
					<input className="input" type="password" {...password.bindToInput} placeholder="password" />
				</div>
				<div className="buttons">
					<button
						className="button"
						onClick={() => {
							intents.SIGN_USER_IN();
						}}
					>
						Google sign in
					</button>
				</div>
			</div>
		</div>
	);
});
