import * as React from 'react';
import { classNames } from '@/imports';

interface Props {
	className?;
	value?;
	onChange;
	onKeyPress?;
	placeholder?;
	type?;
}

export const InputPresentational = React.forwardRef(
	({ className, value = undefined, onChange, onKeyPress, type = 'string', placeholder }: Props, ref) => {
		const classes = classNames(className, 'border');

		const hasValue = value !== undefined || value !== null;

		return (
			<div className={classes}>
				<input
					className="focus:outline-input p-2-4"
					ref={ref}
					{...(hasValue && { value })}
					onChange={onChange}
					onKeyPress={onKeyPress}
					placeholder={placeholder || `${type}...`}
					type={type}
				/>
			</div>
		);
	}
);
