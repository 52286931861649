import * as React from 'react';
import { classNames } from '@/imports';
import { useOnClickOutside } from '@hooks/';

interface Props {
	className?;
	menuClassName?;
	itemClassName?;
	activeItemClassName?;
	options;
	value?;
	defaultValue?;
	trigger?;
	icon?: JSX.Element;
	onChange;
	onOpen?;
}

export const DropdownPresentational = ({
	className,
	menuClassName,
	itemClassName,
	activeItemClassName,
	options,
	value,
	defaultValue,
	trigger,
	onChange,
	onOpen,
	icon
}: Props) => {
	const [opened, setIsOpened] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState(options.find(item => item.value === defaultValue) || {});

	React.useEffect(() => {
		if (opened && onOpen) {
			onOpen();
		}
	}, [opened])

	const getSelected = () => {
		const item = options.find(item => item.value === value);
		if (item) {
			return item.label || item.text;
		}
		return undefined;
	};

	const classes = classNames(className, 'dropdown-presentational', 'relative center-v jc-between cursor-pointer');
	const menuClasses = classNames(
		menuClassName,
		'text-main-font text-xs left-0 mt-2 w-full min-w-max-content cursor-auto absolute top-full bg-white border border-blue-pale-dark rounded z-1000'
	);
	const itemClasses = classNames(itemClassName, 'cursor-pointer p-3-4 min-w-max-content hover:bg-dropdown');
	const activeItemClasses = classNames(activeItemClassName, 'bg-dropdown');

	const menuRef = React.useRef(null);
	useOnClickOutside(menuRef, () => {
		setIsOpened(false);
	});

	return (
		<div ref={menuRef} className={classes} onClick={e => (e.stopPropagation(), setIsOpened(!opened))}>
			{trigger || (
				<div className="center">
					<div className="center-v">{value ? getSelected() : selectedItem.label || selectedItem.text}</div>
					{icon && <div className="center-v ml-2">{icon}</div>}
				</div>
			)}
			{opened && (
				<div className={menuClasses}>
					{options.map(item => {
						const selected = (!trigger && item.value === value) || item.value === selectedItem.value;
						const _itemClasses = classNames(itemClasses, {
							[activeItemClasses]: selected,
							'font-bold': selected
						});

						return (
							<div
								key={item.value}
								className={_itemClasses}
								onClick={e => {
									e.stopPropagation();
									if (!trigger && !value) {
										setSelectedItem(item);
									}
									onChange(e, { options, ...item });
									setIsOpened(false);
								}}
							>
								{item.text}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
