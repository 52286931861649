import {
	deserializeUrlToModel,
	devTools,
	model,
	Model,
	routerSetup as _routerSetup,
	serializeModelToUrl
} from '@/model';
import { raw } from '@nx-js/observer-util';
import { enhancePredicates, enhanceSelectedMetrics } from '@/model/reducer';
import O from 'patchinko/constant';

const onPopState = (model: Model) => {
	O(model, {
		predicates: enhancePredicates(model, model.metricsSchema),
		selectedMetrics: enhanceSelectedMetrics(model, model.metricsSchema)
	});
	if (devTools) {
		devTools.send({}, raw(model));
	}
};

export const routerSetup = () => _routerSetup({ model, serializeModelToUrl, deserializeUrlToModel, onPopState });
