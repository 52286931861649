import { classNames } from '@/imports';
import { view } from 'funwork-js';
import * as React from 'react';

interface Props {
	className?;
	onClick?;
	children;
	inverted?;
}

export const CumulativeMetricButtonPresentational = view(({ className = '', onClick, children, inverted = false }: Props) => {
	const bgColor = {
		inverted: 'bg-white hover:bg-purple-lightened',
		default: 'bg-purple hover:bg-purple-darkened'
	};

	const textColor = {
		inverted: 'text-purple',
		default: 'text-white'
	};

	const type = inverted ? 'inverted' : 'default';

	const classes = classNames(
		className,
		'center-v cursor-pointer p-2-4 font-bold border border-purple rounded',
		bgColor[type],
		textColor[type]
	);

	return (
		<div
			{ ...onClick && { onClick } }
			className={classes}
		>
			{children}
		</div>
	);
});
