import { createIntents } from '@/model';

export const tableIntents: TableIntents = createIntents(
	(present): TableIntents => ({
		SET_LAST_ROW_OF_HOVERED_CONTAINER: containerId =>
			present({ type: 'SET_LAST_ROW_OF_HOVERED_CONTAINER', payload: containerId }),
		SET_ACTIVE_LEVEL: level => present({ type: 'SET_ACTIVE_LEVEL', payload: level }),
		SET_TABLE_WIDTH: width => present({ type: 'SET_TABLE_WIDTH', payload: width }),
		SET_SCROLLED: scrolled => present({ type: 'SET_SCROLLED', payload: scrolled }),
		UPDATE_PINNED_ROWS: startIndex => present({ type: 'UPDATE_PINNED_ROWS', payload: startIndex })
	})
);

export type TableIntents = {
	SET_LAST_ROW_OF_HOVERED_CONTAINER(containerId: string): void;
	SET_ACTIVE_LEVEL(level: number): void;
	SET_TABLE_WIDTH(width: number): void;
	SET_SCROLLED(scrolled: boolean): void;
	UPDATE_PINNED_ROWS(startIndex: number): void;
};
