import { Async, Maybe } from '@functions/crocks';
import { createAxiosInstance } from '@services/ua-app-api';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import axios from 'axios';
import { parseQuery } from 'funwork-js';
import jwtDecode from 'jwt-decode';
import O from 'patchinko/constant';
import { deserializeUrlToModel, model } from './model';

const { Just, Nothing } = Maybe;

window.jwtDecode = jwtDecode;

// ------------------------------------------------ COGNITO ------------------------------------------------

let auth;

function initModel<Dimensions> (dimensions: Dimensions) {
    return {
        dimensions
    }
}

export const initSession = () => {
	auth = initAuth();
	window.auth = auth;
};

export const signIn = () => {
	window.localStorage.setItem('path', window.location.pathname.slice(1));
	window.localStorage.setItem('query', window.location.search);
	auth.getSession();
};

export const signOut = () => {
	auth.signOut();
};

const initAuth = () => {
	const auth = new CognitoAuth({
		AppWebDomain: process.env.APP_WEB_DOMAIN_URL,
		TokenScopesArray: ['openid', 'profile', 'email'],
		RedirectUriSignIn: process.env.LOGIN_REDIRECT_URL,
		RedirectUriSignOut: process.env.LOGOUT_REDIRECT_URL,
		IdentityProvider: 'Google',
		UserPoolId: process.env.COGNITO_USER_POOL_ID,
		ClientId: process.env.COGNITO_APP_CLIENT_ID,
		Storage: window.localStorage
	});

	auth.useCodeGrantFlow();
	auth.userhandler = {
		onSuccess: userSession => {
			createAxiosInstance(userSession.getIdToken().getJwtToken());
			createGapiInstance(jwtDecode(userSession.getIdToken().getJwtToken())['custom:access_token']);

			O(model, {
				...deserializeUrlToModel({
					path: window.localStorage.getItem('path'),
					query: parseQuery(window.localStorage.getItem('query'))
				}),
				user: getUser(userSession)
			});
		},
		onFailure: e => {
			console.error('Failed to sign in', e);
		}
	};
	if (auth.isUserSignedIn()) {
		createAxiosInstance(
			auth
				.getSignInUserSession()
				.getIdToken()
				.getJwtToken()
		);
		createGapiInstance(
			jwtDecode(
				auth
					.getSignInUserSession()
					.getIdToken()
					.getJwtToken()
			)['custom:access_token']
		);

		O(model, {
			user: getUser(auth.getSignInUserSession())
		});
		return auth;
	}
	auth.parseCognitoWebResponse(window.location.href);
	return auth;
};

export const refreshSession = () => {
	auth.refreshSession(
		auth
			.getSignInUserSession()
			.getRefreshToken()
			.getToken()
	);
	createAxiosInstance(
		auth
			.getSignInUserSession()
			.getIdToken()
			.getJwtToken()
	);
	createGapiInstance(
		jwtDecode(
			auth
				.getSignInUserSession()
				.getIdToken()
				.getJwtToken()
		)['custom:access_token']
	);

	O(model, {
		user: getUser(auth.getSignInUserSession())
	});
};

const getUser = userSession => {
	if (!userSession.getIdToken()) return Nothing();
	const payload = jwtDecode(userSession.getIdToken().getJwtToken());
	if (!payload.email) return Nothing();

	return Just({
		email: payload.email,
		name: payload.given_name,
		surname: payload.family_name,
		profilePictureURL: payload.picture
	});
};

// ------------------------------------------------ GAPI ------------------------------------------------

let gapiInstance;

const gapiSpreadsheetsURL = 'https://sheets.googleapis.com/v4/spreadsheets';
const gapiDriveURL = 'https://www.googleapis.com/drive/v2/files';

const createGapiInstance = (token: string) => {
	gapiInstance = axios.create({
		baseURL: '',
		params: {
			alt: 'json',
			key: process.env.GOOGLE_API_KEY
		},
		// headers: { Authorization: `Bearer ${''}` }
		headers: { Authorization: `Bearer ${token}` }
	});
};

type createSheet = (a: string) => Async<void>;
export const createSheet: createSheet = Async.fromPromise(title =>
	gapiInstance.post(gapiSpreadsheetsURL, { properties: { title } })
);

type saveCsvToSheets = (a: string) => (b: string) => Async<void>;
export const saveCsvToSheets: saveCsvToSheets = spreadsheetId =>
	Async.fromPromise(data =>
		gapiInstance.post(`${gapiSpreadsheetsURL}/${spreadsheetId}:batchUpdate`, {
			requests: [
				{
					pasteData: {
						data,
						coordinate: {
							sheetId: 0,
							rowIndex: 0,
							columnIndex: 0
						},
						type: 'PASTE_NORMAL',
						delimiter: ','
					}
				}
			]
		})
	);

type deleteFile = (a: string) => Async<void>;
export const deleteFile: deleteFile = Async.fromPromise(fileId => gapiInstance.delete(`${gapiDriveURL}/${fileId}`));
