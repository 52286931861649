import { shortenNumber } from '@functions/shortenNumber';
import { view } from 'funwork-js';
import * as React from 'react';

interface Props {
	type?: 'string' | 'number';
	value;
	onChange;
	isEditable;
}

const validateNumber = number => -2147483648 < number && number < 2147483647;
const validateString = string => string.length < 255;

export const InputWithConfirmationPresentational = view(({ isEditable, type, value, onChange }: Props) => {
	const insidesRef = React.useRef();
	const inputRef = React.useRef();


	React.useLayoutEffect(() => {
		if (isEditable && inputRef.current) inputRef.current.focus();
	}, [isEditable]);

	const isEditableNode = (
		<div className="center-v bg-sidebar-dd h-3" ref={insidesRef}>
			<input
				className="w-full outline-none bg-transparent text-white text-xxs input:hide-arrows"
				ref={inputRef}
				type={type}
				value={value || ''}
				onChange={e => {
					const val = e.target.value;
					switch (type) {
						case 'string':
							if (validateString(val)) onChange(val);
							break;
						case 'number':
							if (validateNumber(parseInt(val, 10))) onChange(parseInt(val, 10));
							if (val === '') onChange(undefined);
							break;
						default:
					}
				}}
			/>
		</div>
	);

	const notEditable = (
		<div className="cursor-text h-3 w-full ellipsis">
			{type === 'string' && `'`}
			{type === 'string' ? value : value && shortenNumber(value) || ''}
			{type === 'string' && `'`}
		</div>
	);

	return <div className="center-v w-full h-full">{isEditable ? isEditableNode : notEditable}</div>;
});
