import * as React from 'react';
import './MetricPickerLayout.less';
import { view } from 'funwork-js';

interface Props {
	top: JSX.Element | string;
	left: JSX.Element | string;
	right: JSX.Element | string;
	bottom: JSX.Element | string;
}

export const MetricPickerLayout = view(({ top = 'top', left = 'left', right = 'right', bottom = 'bottom' }: Props) => {
	return (
		<div className="metric-picker-layout text-sm">
			<div className="top">{top}</div>
			<div className="center h-full">
				<div className="left">{left}</div>
				<div className="right">{right}</div>
			</div>
			<div className="bottom">
				<div className="add-cumulative-metrics">{bottom}</div>
			</div>
		</div>
	);
});
