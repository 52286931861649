import { MetricValue } from '../model/metrics';
import { createIntents } from '@/model';

export const metricsIntents: MetricsIntents = createIntents(
	(present): MetricsIntents => ({
		SELECT_METRIC: metric => present({ type: 'SELECT_METRIC', payload: metric }),
		SELECT_METRICS: metrics => present({ type: 'SELECT_METRICS', payload: metrics }),
		UNSELECT_METRIC: metric => present({ type: 'UNSELECT_METRIC', payload: metric }),
		UNSELECT_METRICS: metrics => present({ type: 'UNSELECT_METRICS', payload: metrics }),
		UNSELECT_CUMULATIVE_METRIC: metric => present({ type: 'UNSELECT_CUMULATIVE_METRIC', payload: metric }),
		UNSELECT_CUMULATIVE_METRICS: metrics => present({ type: 'UNSELECT_CUMULATIVE_METRICS', payload: metrics }),
		CHECK_CUMULATIVE_METRIC: metric => present({ type: 'CHECK_CUMULATIVE_METRIC', payload: metric }),
		UNCHECK_CUMULATIVE_METRIC: metric => present({ type: 'UNCHECK_CUMULATIVE_METRIC', payload: metric }),
		UNCHECK_ALL_METRICS: () => present({ type: 'UNCHECK_ALL_METRICS' }),
		UPDATE_ORDER_OF_METRICS: event => present({ type: 'UPDATE_ORDER_OF_METRICS', payload: event }),
		SELECT_OPTION_FOR_ADDING_CUMULATIVE_METRICS: option =>
			present({ type: 'SELECT_OPTION_FOR_ADDING_CUMULATIVE_METRICS', payload: option }),
		ADD_NUMBER_FOR_CUMULATIVE_METRICS_SELECTION: number =>
			present({ type: 'ADD_NUMBER_FOR_CUMULATIVE_METRICS_SELECTION', payload: number }),
		UPDATE_FILTER_OF_METRIC_SELECTION: inputString =>
			present({ type: 'UPDATE_FILTER_OF_METRIC_SELECTION', payload: inputString })
	})
);

export type MetricsIntents = {
	SELECT_METRIC(metric: MetricValue);
	SELECT_METRICS(metric: MetricValue[]);
	UNSELECT_METRIC(metric: MetricValue);
	UNSELECT_METRICS(metric: MetricValue[]);
	UNSELECT_CUMULATIVE_METRIC(metric: MetricValue);
	UNSELECT_CUMULATIVE_METRICS(metric: MetricValue[]);
	CHECK_CUMULATIVE_METRIC(metric: MetricValue);
	UNCHECK_CUMULATIVE_METRIC(metric: MetricValue);
	UNCHECK_ALL_METRICS();
	UPDATE_ORDER_OF_METRICS(event);
	SELECT_OPTION_FOR_ADDING_CUMULATIVE_METRICS(option);
	ADD_NUMBER_FOR_CUMULATIVE_METRICS_SELECTION(number);
	UPDATE_FILTER_OF_METRIC_SELECTION(inputString);
}
