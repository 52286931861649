import { model } from '@/model';
import { and, converge, filter, find, getPath, getProp, or, pipe, propEq } from '@functions/crocks';
import { negate } from '@functions/negate';

export const operatorToDropdownOption = (operator: Operator) => ({
	text: operator.label,
	value: operator.operator,
	operand: operator.operand
});

export const getMaybeOperatorSchemaByTypeAndOperator = type => operator =>
	find(propEq('type')(type))(model.filtersSchema)
		.chain(getProp('operators'))
		.chain(find(propEq('operator')(operator)));

export const getOperandByTypeAndOperator = type => operator =>
	getMaybeOperatorSchemaByTypeAndOperator(type)(operator)
		.chain(getProp('operand'))
		.option('');

export const getOperatorDropdownOptionsOfFilterOfDimension = operator => type => dimension => metric =>
	[]
		.concat(find(and(propOperatorEqTo(operator))(propOperandNotEqToStringArray))(getOperatorsOfType(type)).option([]))
		.concat(
			converge(
				operators => dimensionPredicate =>
					operators.filter(
						or(
							pipe(
								({ operator }) => propEq('operator')(operator),
								fn => dimensionPredicate.find(fn),
								negate
							)
						)(propOperandEqToStringArray)
					),
				({ type }) => getOperatorsOfType(type),
				({ dimension }) =>
					filter(and(propEq('breakdown')(dimension), propEq('metric')(metric)))(Object.values(model.predicates.byIds))
			)({ type, dimension })
		)
		.map(operatorToDropdownOption);

export const getOperatorDropdownOptionsOfRawFilterDimension = operator => type => dimension =>
	[]
		.concat(find(and(propOperatorEqTo(operator))(propOperandNotEqToStringArray))(getOperatorsOfType(type)).option([]))
		.concat(
			converge(
				operators => rawFiltersOfDimension =>
					operators.filter(
						or(
							pipe(
								({ operator }) => propEq('operator')(operator),
								fn => rawFiltersOfDimension.find(fn),
								negate
							)
						)(propOperandEqToStringArray)
					),
				({ type }) => getOperatorsOfType(type),
				({ dimension }) => filter(propEq('dimension')(dimension))(model.rawFilters)
			)({ type, dimension })
		)
		.map(operatorToDropdownOption);

export const getOperatorsOfType = type =>
	find(propEq('type')(type))(model.filtersSchema)
		.chain(getProp('operators'))
		.option([]);

export const getOperatorTypeOfMetric = metric => getPath([metric, 'type'])(model.metricsSchema).option(null);
export const getOperatorTypeOfRawField = rawField =>
	find(propEq('value')(rawField))(model.rawFieldsSchema)
		.chain(getProp('type'))
		.option('');

export const propOperandEqToStringArray = propEq('operand')('string[]');

export const propOperandNotEqToStringArray = pipe(
	propOperandEqToStringArray,
	negate
);

export const propOperatorEqTo = propEq('operator');
