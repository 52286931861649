import { cx } from 'emotion';
import * as React from 'react';
import './MiniLabelPresentational.less';

export const MiniLabelPresentational = ({ className = '', text, icon }) => {
	const classes = cx(className, 'mini-label-presentational');
	return (
		<div className={classes}>
			<div className="label">{text}</div>
			{icon}
		</div>
	);
};
