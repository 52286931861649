import { classNames } from '@/imports';
import React from 'react';

interface Props {
	opened: boolean;
	className?: string;
	arrowPosition?: 'top' | 'bottom' | 'none';
	children: string | JSX.Element;
}

export const Popup = React.forwardRef<HTMLDivElement, Props>(
	({ opened, className, arrowPosition = 'bottom', children }, ref) => {
		if (!opened) return null;

		const classes = classNames(className, 'popup', `arrow-${arrowPosition}`);

		return (
			<div ref={ref} className={classes}>
				{children}
			</div>
		);
	}
);
