import * as React from 'react';
import './MetricPickerTitleAddition.less';
import { view } from 'funwork-js';

interface Props {
	content: string;
	onClick;
}

export const MetricPickerTitleAddition = view(({ content, onClick }: Props) => {
	return (
		<div className="metric-picker-title-addition" onClick={onClick}>
			{content}
		</div>
	);
});
