import { DatePicker } from '@/_components/DatePicker/DatePicker';
import { InputWithConfirmationPresentational } from '@reusable/InputWithConfirmationPresentational/InputWithConfirmationPresentational';
import * as React from 'react';

interface Props {
	value;
	operand;
	onInput;
	isEditable;
}

export const PredicateInputPresentational = ({ isEditable, value, operand, onInput }: Props) => {
	return (
		<div className="predicate-input-presentational center-v h-full w-full z-1000">
			{(() => {
				if (operand === 'string') {
					return (
						<InputWithConfirmationPresentational
							isEditable={isEditable}
							type="string"
							value={value}
							onChange={onInput}
						/>
					);
				}
				if (operand === 'string[]') {
					return (
						<InputWithConfirmationPresentational
							isEditable={isEditable}
							type="string"
							value={value}
							onChange={onInput}
						/>
					);
				}
				if (operand === 'isoDate') {
					return (
						<DatePicker
							className="w-full outline-none bg-transparent text-white text-xxs input:hide-arrows"
							popperClassName="z-1000"
							classNames="hello"
							selected={value && new Date(value)}
							dateFormat="yyyy-MM-dd"
							onChange={val => onInput(val)}
						/>
					);
				}
				if (operand === 'number') {
					return (
						<InputWithConfirmationPresentational
							isEditable={isEditable}
							type="number"
							value={value}
							onChange={onInput}
						/>
					);
				}
				return null;
			})()}
		</div>
	);
};
