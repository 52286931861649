export interface SidebarModel {
	isSidebarOpened: boolean;
	isRawFiltersSectionOpened: boolean;
	isDimensionsSecionOpened: boolean;
	isExportModalOpened: boolean;
}

export const sidebarModel: SidebarModel = {
	isSidebarOpened: true,
	isRawFiltersSectionOpened: true,
	isDimensionsSecionOpened: true,
	isExportModalOpened: true
};
