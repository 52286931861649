export const shortenNumber = (value: number, shorten: boolean = true, decimals: number = 1): string => {
	if (shorten) {
		const symbols = [
			{ value: 1e18, symbol: 'E' },
			{ value: 1e15, symbol: 'P' },
			{ value: 1e12, symbol: 'T' },
			{ value: 1e9, symbol: 'G' },
			{ value: 1e6, symbol: 'M' },
			{ value: 1e3, symbol: 'k' }
		];
		const regExp = /\.0+$|(\.[0-9]*[1-9])0+$/;
		for (const item of symbols) {
			if (value >= item.value) {
				return (value / item.value).toFixed(decimals).replace(regExp, '$1') + item.symbol;
			}
		}
		return value.toFixed(decimals).replace(regExp, '$1');
	}
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
