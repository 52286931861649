import _DatePicker from 'react-datepicker';
import React from 'react';
import './DatePicker.less';

export const DatePicker = props => {
	const onChange = e => {
		e.setUTCHours(0)
		props.onChange(e);
	};
	
	// eslint-disable-next-line react/jsx-pascal-case
	return <_DatePicker {...props} onChange={onChange} />;
};
