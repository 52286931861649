import { RawFilter } from '@/dsl';
import { createIntents } from '@/model';

export const rawFiltersIntents: RawFiltersIntents = createIntents(
	(present): RawFiltersIntents => ({
		CREATE_RAW_FILTER: ({ dimension }) => present({ type: 'CREATE_RAW_FILTER', payload: { dimension } }),
		REMOVE_RAW_FILTER: (rawFilter: RawFilter) => present({ type: 'REMOVE_RAW_FILTER', payload: rawFilter }),
		UPDATE_RAW_FILTER: (oldRawFilter: RawFilter) => update =>
			present({ type: 'UPDATE_RAW_FILTER', payload: { oldRawFilter, update } })
	})
);

export type RawFiltersIntents = {
	CREATE_RAW_FILTER(rawFilterPredicate);
	REMOVE_RAW_FILTER(rawFilterPredicate);
	UPDATE_RAW_FILTER(rawFilterPredicate);
};
