import axios, { AxiosInstance } from 'axios';
import { Async } from '@functions/crocks';

const ANALYTICS_API_ENDPOINT = '/analytics-api';
const INTEGRATION_API_ENDPOINT = '/invoke-etl-retry';

let instance: AxiosInstance;

export const createAxiosInstance = token => {
	instance = axios.create({
		baseURL: process.env.API_ENDPOINT,
		headers: { Authorization: `${token}` }
	});
};

export const getInitialData = () => instance(`${ANALYTICS_API_ENDPOINT}/schema`);

export const fetchAnalyticsData = props => instance.post(`${ANALYTICS_API_ENDPOINT}`, props);

export const exportAnalyticsData = props =>
	instance.post(`${ANALYTICS_API_ENDPOINT}`, props, { headers: { 'Content-Type': 'text/csv' } });

export const retryFeed = Async.fromPromise(id => instance(`${INTEGRATION_API_ENDPOINT}/?requestID=${id}`));
