import * as React from 'react';
import { intents } from '@/intents';
import { classNames } from '@/imports';

interface Props {
	className?: string;
}

export const SignOut = ({ className = '' }: Props) => {
	const classes = classNames(className, 'cursor-pointer');

	return (
		<div className={classes} onClick={intents.SIGN_USER_OUT}>
			Sign out
		</div>
	);
};
