import { classNames } from '@/imports';
import { intents } from '@/intents';
import { model } from '@/model';
import * as Reusable from '@/Reusable';
import { view } from 'funwork-js';
import * as React from 'react';

export const Navigation = view(() => {
	const { path } = model;

	const goToPage = page => () => {
		intents.SET_URL(page);
	};

	const pageClasses = _page => classNames({ 'text-blue': _page.includes(path) });

	return (
		<div className="center w-full children:mr-4">
			<div className={pageClasses(['', 'explore'])}>
				<Reusable.ViewIcon className="mr-2" name="chart-pie" />
				<span className="cursor-pointer uppercase" onClick={goToPage('explore')}>
					Explore
				</span>
			</div>
			{/* <div className={pageClasses(['login'])}>
				<Reusable.ViewIcon className="mr-2" name="tasks-alt" weight="solid" />
				<span className="cursor-pointer uppercase" onClick={goToPage('login')}>
					Login
				</span>
			</div> */}
		</div>
	);
});
