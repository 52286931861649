import { classNames } from '@/imports';
import { intents } from '@/intents';
import { isLastActiveDay, Metric } from '@/Metrics';
import { model } from '@/model';
import { MetricFormat } from '@/model/metrics';
import * as Reusable from '@/Reusable';
import ErrorMessage from '@ErrorMessage';
import { concat, converge, flip, getPath, getProp, unit, Maybe } from '@functions/crocks';
import { debounce } from '@functions/debounce';
import { last } from '@functions/last';
import { Loading } from '@reusable/Loading/Loading';
import { Cell } from '@reusable/Table/table-types';
import { TableCell } from '@reusable/Table/TableCell/TableCell';
import * as dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { view } from 'funwork-js';
import React from 'react';
import { ofType, unionize } from 'unionize';
import { RowList } from './RowList';
import './Table.less';

const { Just, Nothing } = Maybe;

window.dayjs = dayjs;

dayjs.extend(isSameOrAfter);

export const Table = view(() => {
	return <TablePresentational rowList={<RowList />} />;
});

const TablePresentational = view(({ rowList }) => {
	const { activeLevel, tableState } = model;
	const [scrolled, setScrolled] = React.useState();

	const tableRef = React.useRef();

	const setNameColumnShadow = debounce(250, e => {
		const { scrollLeft } = tableRef.current;
		if (scrollLeft === 0 && scrolled) {
			setScrolled(false);
			return;
		}
		if (scrollLeft !== 0 && !scrolled) {
			setScrolled(true);
		}
	});

	const resizeEventHandler = debounce(150, () => {
		const newWidth = tableRef.current.offsetWidth;
		if (model.tableWidth !== newWidth) {
			intents.SET_TABLE_WIDTH(newWidth);
		}
	});

	React.useEffect(() => {
		intents.SET_TABLE_WIDTH(tableRef.current.offsetWidth);
		window.addEventListener('resize', resizeEventHandler);

		return () => {
			window.removeEventListener('resize', resizeEventHandler);
		};
	}, []);

	const classes = classNames('table', activeLevel === -1 ? '' : `active-level-${activeLevel}`, { scrolled });

	return (
		<div component="table">
			<div ref={tableRef} className={classes} onScroll={setNameColumnShadow}>
				<div className="body">
					{tableState === 'notLoading' ? (
						<div className="row">
							<div className="cell">
								<div className="loader-wrapper">Nothing requested</div>
							</div>
						</div>
					) : tableState === 'loading' ? (
						<div className="row">
							<div className="cell">
								<div className="loader-wrapper">
									<Loading />
								</div>
							</div>
						</div>
					) : tableState === 'error' ? (
						<div className="row">
							<div className="cell">
								<ErrorMessage message="Failed to load the data" />
							</div>
						</div>
					) : model.flattenedData.length === 0 ? (
						<div className="row">
							<div className="cell">
								<ErrorMessage message="No data" />
							</div>
						</div>
					) : (
						rowList
					)}
				</div>
			</div>
		</div>
	);
});

Table.displayName = 'Table';

export const CellList = view(({ fields, path, header, active, last: isLast, onClick }) => {
	return (
		<>
			{fields.map((cell, index) => {
				const first = index === 0;
				const metric = cell.key.value;
				const level = path.length - 1;
				const value = getCellValue(model.columns, header, cell);
				const icon = isLastActiveDay(metric) && !header ? getActivityIcon(cell.value) : undefined;

				const bg = !header ? getHeatmapBg(metric)(value as number) : '';

				return (
					<TableCell
						key={`${path.join('-')}-${index}`}
						first={first}
						order={first && !header && last(path) + 1}
						metric={metric}
						padding={first && !header ? (path.length - 1) * 16 : 0}
						bg={bg}
						value={value}
						header={header}
						onClick={first ? onClick : unit}
						icon={icon}
						last={index + 1 === fields.length}
						lastLevel={path.length === model.breakdowns.allIds.length}
						lastRow={isLast}
						level={level}
						lastBreakdown={level === model.breakdowns.allIds.length - 1}
					/>
				);
			})}
		</>
	);
});

CellList.displayName = 'CellList';

const getActivityIcon = (date: string) => (isYesterdayOrLater(date) ? <ViewActiveIcon /> : <ViewInactiveIcon />);

const ViewActiveIcon = () => <Reusable.ViewIcon name="play" size="nano" weight="solid" className="text-c-accent2" />;
const ViewInactiveIcon = () => <Reusable.ViewIcon name="stop" size="nano" weight="solid" className="text-red" />;

const isYesterdayOrLater = (date: string): boolean => dayjs(date).isSameOrAfter(dayjs().subtract(1, 'day'), 'day');

const getCellValue = (columns, isHeader: boolean, cell: Cell): number | string => {
	if (isHeader) return cell.value;
	const defaultValue = 'No data';
	const cellValueType = getCellValueType(columns, cell);
	return CellValueType.match(cellValueType, {
		Number: ({ value, format }) => {
			const postfix = getProp('postfix')(format).alt(Just(''));
			const prefix = getProp('prefix')(format).option('');
			return value
				.map(parseFloat)
				.map(toFixed(format.round))
				.map(
					converge(
						concat,
						number =>
							getProp(1)(number.split('.'))
								.map(flip(concat)('.'))
								.option(''),
						number => Number(number.split('.')[0]).toLocaleString()
					)
				)
				.map(number => prefix.concat(number))
				.concat(postfix)
				.option(defaultValue);
		},
		String: ({ value }) => value.option(defaultValue),
		Date: ({ value }) => value.map(formatDate).option(defaultValue)
	});
};

const CellValueType = unionize({
	Number: ofType<{ value; format?: MetricFormat }>(),
	Date: ofType<{ value; format?: MetricFormat }>(),
	String: ofType<{ value; format?: MetricFormat }>()
});

// eslint-disable-next-line consistent-return
const getCellValueType = (columns, cell: Cell) => {
	const { type, format }: { type: string; format?: MetricFormat } = columns.find(metric => {
		const regexp = /^d(\d+)_(.*)$/;
		const checked = metric.value.match(regexp);
		const value = checked ? checked[2] : metric.value;
		return getCellColumn(cell).equals(Just(value));
	}) || { type: 'string', format: {} };
	const { value } = cell;
	const safeValue = !value ? Nothing() : Just(value);
	if (type === 'number') return CellValueType.Number({ format, value: safeValue });
	if (type === 'date') return CellValueType.Date({ format, value: safeValue });
	if (type === 'string') return CellValueType.String({ format, value: safeValue });
};

const getCellColumn = (cell: Cell) => {
	return getPath(['key', 'value'])(cell);
};

const formatDate = (date: string): string => {
	return dayjs(date).format('YYYY-MM-DD');
};

const toFixed = (fractionDigits: number) => {
	return (number: number): string => {
		return number.toFixed(fractionDigits);
	};
};

const getHeatmapBg = (metric: Metric) => (_value: number) => {
	if (
		metric === 'return_on_investment' ||
		metric === 'gross_return_on_active_spend' ||
		metric === 'net_return_on_active_spend'
	) {
		const value = parseFloat(_value);
		const index = heatmapMetrics[metric].thresholds.findIndex(val => {
			return isBetween(val[0])(val[1])(value);
		});
		if (index === -1) return '';
		const [color1, color2] = heatmapMetrics[metric].colors[index];
		const [threshold1, threshold2] = heatmapMetrics[metric].thresholds[index];
		const percents = lerp(threshold1)(threshold2)(value);
		return colorToHsl(colorBetween(color1)(color2)(percents));
	}
	return '';
};

const lerp = (a: number) => (b: number) => (c: number): number => (a - c) / (a - b);
const movePercents = (from: number) => (to: number) => (percents: number) => {
	const direction = from < to ? 1 : -1;
	return from + direction * (Math.abs(from - to) * percents);
};

const colorBetween = (color1: Color) => (color2: Color) => (percents: number): Color => ({
	hue: movePercents(color1.hue)(color2.hue)(percents),
	saturation: movePercents(color1.saturation)(color2.saturation)(percents),
	lightness: movePercents(color1.lightness)(color2.lightness)(percents)
});

type Color = {
	hue: number;
	saturation: number;
	lightness: number;
};

const createColor = (hue: number) => (saturation: number) => (lightness: number) => ({ hue, saturation, lightness });
const colorToHsl = (c: Color) => `hsl(${c.hue}, ${c.saturation}%, ${c.lightness}%)`;

const isBetween = (min: number) => (max: number) => (c: number): boolean => min <= c && c <= max;

const red = createColor(5)(70)(67);
const white = createColor(0)(0)(100);
const green = createColor(150)(42)(55);

const roiThresholds = [[-9999999, -100], [-100, 0], [0, 30], [30, 9999999]];
const roasThresholds = [[-9999999, 0], [0, 100], [100, 130], [130, 9999999]];

const thresholdsColors = [[red, red], [red, white], [white, green], [green, green]];

const heatmapMetrics = {
	return_on_investment: {
		thresholds: roiThresholds,
		colors: thresholdsColors
	},
	gross_return_on_active_spend: {
		thresholds: roasThresholds,
		colors: thresholdsColors
	},
	net_return_on_active_spend: {
		thresholds: roasThresholds,
		colors: thresholdsColors
	}
};
