import * as React from 'react';
import { App } from './App';

export const Wrapper = () => {
	return (
		<div>
			<App />
		</div>
	);
};
