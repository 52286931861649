import * as Reusable from '@/Reusable';
import * as React from 'react';
import { classNames } from '@/imports';
import { SidebarItemPresentational } from '@components/Sidebar/SidebarItemPresentational';

interface Props {
	metricsDropdown;
	activeSort;
	onSortUp;
	onSortDown;
	onRemove;
}

export const DimensionSortingRowPresentational = ({
	metricsDropdown,
	activeSort,
	onSortUp,
	onSortDown,
	onRemove
}: Props) => {
	const active = 'pointer-events-none';
	const inactive = 'text-sidebar-font-dark zoom'

	return (
		<SidebarItemPresentational
			className="dimension-sorting-row-presentational cursor-auto"
			icon={<Reusable.ViewIcon name="times" size="nano" />}
			onIconClick={onRemove}
		>
			<div className="center-v text-xs pl-14 w-full">
				<div className="center-v jc-between w-full">
					<div className="center-v">
						<div className="mr-2">{metricsDropdown}</div>
					</div>
					<div className="center-v">
						<Reusable.ViewIcon
							className={classNames('mr-1', { [active]: activeSort === 'ASC', [inactive]: activeSort === 'DESC' })}
							name="arrow-alt-circle-up"
							weight="solid"
							size="text-xs"
							onClick={onSortUp}
						/>
						<Reusable.ViewIcon
							className={classNames({ [active]: activeSort === 'DESC', [inactive]: activeSort === 'ASC',  })}
							name="arrow-alt-circle-down"
							weight="solid"
							size="text-xs"
							onClick={onSortDown}
						/>
					</div>
				</div>
			</div>
		</SidebarItemPresentational>
	);
};
