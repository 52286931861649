import * as React from 'react';
import { classNames } from '@/imports';

interface Props {
	content: JSX.Element | number;
	isInverted?: boolean;
	isPartial?: boolean;
	onClick;
}

const textColor = {
	partial: 'text-c-accent2-pale hover:text-c-accent2-pale',
	inverted: 'text-c-accent2-pale hover:text-c-accent2-pale',
	default: 'text-white'
};
const bgColor = {
	partial: 'bg-c-accent2-pale-transparent hover:bg-c-accent2-pale-lightened',
	inverted: 'bg-white hover:bg-c-accent2-pale-lightened',
	default: 'bg-c-accent2-pale hover:bg-c-accent2-pale-darkened'
};

export const MetricNumberButtonPresentational = ({ content, isInverted = true, isPartial = false, onClick }: Props) => {
	const type = 
		isPartial ? 'partial'
		: isInverted && !isPartial ? 'inverted'
		: 'default';

	const classes = classNames(
		'cursor-pointer metric-number-button font-bold rounded',
		'center h-8 w-8 p-0 mr-2 border border-c-accent2-pale',
		'last-child:mr-0',
		textColor[type],
		bgColor[type],
	);

	return (
		<div className={classes} onClick={onClick}>
			{content}
		</div>
	);
};
