import { classNames } from '@/imports';
import { SidebarItemPresentational } from '@components/Sidebar/SidebarItemPresentational';
import { propEq } from '@functions/crocks';
import { useOnClickOutside } from '@hooks/';
import * as Reusable from '@/Reusable';
import { view } from 'funwork-js';
import * as React from 'react';

interface Props {
	active: boolean;
	onConfirm;
	onCancel;
	onBeginEditing;
	dropdown1;
	dropdown2;
	input;
	onRemove;
	className?: string;
}

export const FilterRowPresentational = view(
	({ active, onConfirm, onCancel, onBeginEditing, dropdown1, dropdown2, input, onRemove, className = '' }: Props) => {
		const classes = classNames(className, 'outline-none relative center-v w-full', active ? 'pl-11' : 'pl-14');
		const activeClasses = active ? 'p-2 bg-sidebar-dd' : '';
		const dropdownClasses = classNames('mr-2 underline', activeClasses);
		const dropdownClasses2 = classNames('mr-2', activeClasses);
		const inputClasses = classNames('overflow-hidden w-full', activeClasses);
		const itemRef = React.useRef();

		useOnClickOutside(itemRef, () => active && onCancel());

		const onKeyPress = e => propEq('key')('Enter')(e) && onConfirm(e);

		return (
			<SidebarItemPresentational
				className="filter-row-presentational text-xxs cursor-auto"
				onIconClick={onRemove}
				icon={<Reusable.ViewIcon name="times" size="nano" />}
			>
				<div ref={itemRef} tabIndex={0} className={classes} onClick={onBeginEditing} onKeyPress={onKeyPress}>
					<div className="center-v w-full">
						<div className={dropdownClasses}>{dropdown1}</div>
						<div className={dropdownClasses2}>{dropdown2}</div>
						<div className={inputClasses}>{input}</div>
						{active && (
							<Reusable.ViewIcon
								className="text-sidebar-font-light ml-2"
								name="check"
								weight="solid"
								size="nano"
								onClick={onConfirm}
							/>
						)}
					</div>
				</div>
			</SidebarItemPresentational>
		);
	}
);
