import { DragAndDrop } from '@reusable/DragAndDrop/DragAndDrop';
import * as React from 'react';

interface Props {
	onDragEnd;
	direction?: 'vertical' | 'horizontal';
	children: ((props: ProvidedProps) => JSX.Element)[];
	className?: string;
}

export const DnDListPresentational = ({ onDragEnd, direction = 'vertical', children, className }: Props) => (
	<DragAndDrop onDragEnd={onDragEnd} direction={direction} parentClassName={className}>
		{children}
	</DragAndDrop>
);

interface ProvidedProps {
	innerRef;
	draggableProps: DraggableProps;
	dragHandleProps: DragHandleProps;
}

interface DraggableProps {
	'data-react-beautiful-dnd-draggable': string;
	onTransitionEnd?;
	style: { tranform?: string; transition?: string };
}

interface DragHandleProps {
	'aria-roledescription': string;
	'data-react-beautiful-dnd-drag-handle': string;
	draggable: boolean;
	onBlur?;
	onDragStart?;
	onFocus?;
	onKeyDown?;
	onMouseDown?;
	onTouchStart?;
	tabIndex: number;
}
