import { intents } from '@/intents';
import { metricToDropdown } from '@/Metrics';
import { model } from '@/model';
import { PredicateInputPresentational } from '@components/Dimensions/PredicateInputPresentational/PredicateInputPresentational';
import { SidebarDropdownPresentational } from '@components/Sidebar/SidebarDropdownPresentational';
import {
	getOperandByTypeAndOperator,
	getOperatorDropdownOptionsOfFilterOfDimension,
	getOperatorTypeOfMetric
} from '@dsl/operators';
import { FilterRowPresentational } from '@reusable/FilterRowPresentational/FilterRowPresentational';
import { view } from 'funwork-js';
import React from 'react';

export const DimensionFilterRow = view(({ breakdown, id, label, metric, operand, operator, value }) => {
	const [temporaryMetricValue, setTemporaryMetricValue] = React.useState(metric);
	const [temporaryOperatorValue, setTemporaryOperatorValue] = React.useState(operator);
	const [temporaryInputValue, setTemporaryInputValue] = React.useState(value);

	const [isEditable, setIsEditable] = React.useState(false);

	let operatorType = getOperatorTypeOfMetric(temporaryMetricValue);

	React.useEffect(() => {
		operatorType = getOperatorTypeOfMetric(temporaryMetricValue);
	}, [temporaryMetricValue]);

	const onConfirm = e => {
		e.stopPropagation();
		setIsEditable(false);
		intents.UPDATE_FILTER_PREDICATE_OF_DIMENSION(breakdown)(temporaryMetricValue)({
			...model.predicates.byIds[id],
			operator: temporaryOperatorValue,
			label: temporaryOperatorValue,
			// label: temporaryOperatorValue.text,
			value: temporaryInputValue
		});
		if (temporaryMetricValue !== metric || temporaryOperatorValue !== operator) {
			intents.REMOVE_PREDICATE_OF_DIMENSION(breakdown)(metric)(operator);
		}
	};

	const onRemove = () => intents.REMOVE_PREDICATE_OF_DIMENSION(breakdown)(metric)(operator);
	const onCancel = () => {
		setIsEditable(false);
		setTemporaryMetricValue(metric);
		setTemporaryOperatorValue(operator);
		setTemporaryInputValue(value);
	};

	const metricDropdown = (
		<SidebarDropdownPresentational
			onOpen={() => setIsEditable(true)}
			options={model.selectedMetrics
				.map(metricToDropdown)
				.map(item => ({ ...item, label: <div className="ellipsis max-w-15">{item.text}</div> }))}
			value={temporaryMetricValue}
			onChange={(_, { value: val }) => {
				if (operatorType !== getOperatorTypeOfMetric(val)) {
					setTemporaryInputValue('');
				}
				setTemporaryMetricValue(val);
			}}
		/>
	);

	const operatorDropdown = (
		<SidebarDropdownPresentational
			onOpen={() => setIsEditable(true)}
			options={getOperatorDropdownOptionsOfFilterOfDimension(operator)(operatorType)(breakdown)(temporaryMetricValue)}
			value={temporaryOperatorValue}
			onChange={(_, { value: val }) => setTemporaryOperatorValue(val)}
		/>
	);

	const input = (
		<PredicateInputPresentational
			value={temporaryInputValue}
			operand={getOperandByTypeAndOperator(operatorType)(temporaryOperatorValue)}
			onInput={setTemporaryInputValue}
			isEditable={isEditable}
		/>
	);

	return (
		<FilterRowPresentational
			dropdown1={metricDropdown}
			dropdown2={operatorDropdown}
			input={input}
			onRemove={onRemove}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onBeginEditing={() => setIsEditable(true)}
			active={isEditable}
		/>
	);
});

DimensionFilterRow.displayName = 'DimensionFilterRow';
