import { cx } from 'emotion';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './DragAndDrop.less';

interface Props {
	parentClassName?: string;
	isScrollable?: boolean;
	direction?: 'vertical' | 'horizontal';
	children?;
	onDragEnd;
	onBeforeDragStart?;
	portal?;
}

export const DragAndDrop = ({
	parentClassName,
	direction = 'vertical',
	children,
	onDragEnd,
	onBeforeDragStart
}: Props) => {
	const parentClasses = cx(direction, parentClassName);

	return (
		<DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
			<Droppable droppableId="droppable" direction={direction}>
				{(provided, _snapshot) => {
					return (
						<div ref={provided.innerRef} {...provided.droppableProps} className={parentClasses}>
							{children.map((item, index) => {
								return (
									<Draggable key={index} draggableId={`${index}`} index={index}>
										{item}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
};
