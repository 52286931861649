import { MetricPicker } from './Home/metrics';
import { intents } from '@/intents';
import { model } from '@/model';
import { Table } from '@/_components/_Table/Table';
import { Modal } from '@components/reusable/Modal/Modal';
import { view } from 'funwork-js';
import * as React from 'react';

// ------------------------------------------------ MODEL ------------------------------------------------

const Model = {
    
}

// ------------------------------------------------ VIEW ------------------------------------------------

export const View = view(() => {
	return (
		<div className="flex w-full h-full bg-primary">
			<Modal isOpened={model.isMetricPickerOpened} onClose={intents.CLOSE_METRIC_PICKER}>
				<MetricPicker />
			</Modal>
			<div className="flex flex-col relative w-full h-full p-8-4-0 children:flex-shrink-0">
				<Table />
			</div>
		</div>
	);
});
